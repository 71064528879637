/* You can add global styles to this file, and also import other style files */
body {
  height: 100%;
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
}

.prevent-scrolling {
  overflow: hidden !important;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid lightgray;
  margin: 1em 0;
  padding: 0;
}

.skeleton-box {
  display: inline-block;
  width: 100%;
  height: 12px;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  border-radius: 4px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
