html {
  font-family: 'HelveticaNeueLTPro', sans-serif;
}

.formular-field-fullwidth {
  grid-column: auto;

  @media only screen and (min-width: 1025px) {
    grid-column: 1 / span 2;
  }
}

.formcontrol-grid-readonly-item {
  display: flex;
  flex-direction: column;
  gap: 2px;

  > dx-text:first-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
